<template>
  <b-button variant="link" block @click.prevent="$emit('click')">
    {{ text }}
    <font-awesome-icon :icon="icon" fixed-width />
  </b-button>
</template>

<script>
export default {
  name: "ToggleMoreButton",
  props: {
    expanded: { type: Boolean }
  },
  computed: {
    icon() {
      return "chevron-" + (this.expanded ? "up" : "down");
    },
    text() {
      return "Show " + (this.expanded ? "Less" : "More");
    }
  }
};
</script>
