<template>
  <b-button
    :href="href"
    target="_blank"
    rel="noopener"
    variant="link"
    size="lg"
    :title="title ? title : icon"
    @click="track"
  >
    <font-awesome-icon :icon="['fab', icon]" />
  </b-button>
</template>

<script>
export default {
  name: "VendorSocialButton",
  props: {
    vendor: { type: Object, required: true },
    title: { type: String },
    icon: { type: String, required: true },
    href: { type: String, required: true }
  },
  methods: {
    track() {
      this.$gtag.event(this.icon, {
        event_category: "vendor",
        event_label: this.vendor.name
      });
    }
  }
};
</script>
