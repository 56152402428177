<template>
  <div v-if="show" id="testimonials" class="platter mb-4 pb-3">
    <h3 class="mb-3">
      <strong>Testimonials</strong>
    </h3>

    <b-row cols="1" cols-md="2">
      <b-col v-for="t in featuredTestimonials" :key="t.copy">
        <vendor-testimonial :testimonial="t" />
      </b-col>
    </b-row>

    <transition
      name="fade"
      enter-active-class="fadeInUp"
      leave-active-class="fadeOut"
    >
      <b-row v-if="expanded" cols="1" cols-md="2">
        <b-col v-for="t in otherTestimonials" :key="t.copy">
          <vendor-testimonial :testimonial="t" />
        </b-col>
      </b-row>
    </transition>

    <toggle-more-button
      v-if="showToggleMore"
      :expanded="expanded"
      @click="$emit('toggle-expanded')"
    />
  </div>
</template>

<script>
import VendorTestimonial from "./VendorTestimonial";
import ToggleMoreButton from "./ToggleMoreButton";

import Enumerable from "linq";

export default {
  name: "VendorTestimonials",
  components: { VendorTestimonial, ToggleMoreButton },
  props: {
    testimonials: { type: Array },
    expanded: { type: Boolean }
  },
  computed: {
    show() {
      return (this.testimonials?.length ?? 0) > 0;
    },
    showToggleMore() {
      return (this.testimonials?.length ?? 0) > 2;
    },
    featuredTestimonials() {
      return Enumerable.from(this.testimonials)
        .orderBy(t => !t.featured)
        .take(2)
        .toArray();
    },
    otherTestimonials() {
      return Enumerable.from(this.testimonials)
        .orderBy(t => !t.featured)
        .skip(2)
        .toArray();
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#testimonials",
        title: "Testimonials",
        icon: "quote-left"
      });
    }
  }
};
</script>
