<template>
  <div v-if="show" id="contacts" class="platter mb-4 pb-3">
    <h3 class="mb-3"><strong>Contacts</strong></h3>

    <b-row cols="1" cols-md="2">
      <b-col v-for="c in featuredContacts" :key="c.id">
        <vendor-contact :contact="c" />
      </b-col>
    </b-row>

    <transition
      name="fade"
      enter-active-class="fadeInUp"
      leave-active-class="fadeOut"
    >
      <b-row v-if="expanded" cols="1" cols-md="2">
        <b-col v-for="c in otherContacts" :key="c.id">
          <vendor-contact :contact="c" />
        </b-col>
      </b-row>
    </transition>

    <toggle-more-button
      v-if="showToggleMore"
      :expanded="expanded"
      @click="$emit('toggle-expanded')"
    />
  </div>
</template>

<script>
import VendorContact from "./VendorContact";
import ToggleMoreButton from "./ToggleMoreButton";

import Enumerable from "linq";

export default {
  name: "VendorContacts",
  components: { VendorContact, ToggleMoreButton },
  props: {
    contacts: { type: Array },
    expanded: { type: Boolean }
  },
  computed: {
    show() {
      return (this.contacts?.length ?? 0) > 0;
    },
    showToggleMore() {
      return (this.otherContacts?.length ?? 0) > 0;
    },
    contactEnumerable() {
      return Enumerable.from(this.contacts).orderBy(c => c.lastName);
    },
    featuredContacts() {
      return this.contactEnumerable
        .orderBy(t => !t.featured)
        .take(2)
        .toArray();
    },
    otherContacts() {
      return this.contactEnumerable
        .orderBy(t => !t.featured)
        .skip(2)
        .toArray();
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#contacts",
        title: "Contacts",
        icon: "users"
      });
    }
  }
};
</script>
