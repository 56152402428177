<template>
  <div id="vendor-header" class="platter text-center mb-4 pb-0">
    <div class="top-actions">
      <vendor-favorite-toggle :vendor="vendor" show-label />
      <b-button
        :to="'/admin/' + vendor.key"
        v-if="vendor.canEdit || vendor.canReport"
        size="sm"
        variant="outline-dark"
        pill
        class="ml-2 manage accent"
      >
        <span class="d-none d-sm-inline">Manage&nbsp;</span>
        <font-awesome-icon icon="tools" />
      </b-button>
    </div>
    <b-img
      v-if="vendor.logoSrc"
      fluid
      :src="vendor.logoSrc"
      class="my-3 my-sm-4"
      :title="vendor.name"
    />
    <h1 v-if="showTitle">
      {{ vendor.name }}
    </h1>
    <p class="text-muted">
      {{ vendor.shortDesc }}
    </p>
    <vc-vendor-badges :vendor="vendor" enable-links enable-modals />
    <vendor-header-actions
      :vendor="vendor"
      @navigate-to="$emit('navigate-to', $event)"
    />

    <ul id="social-icons" class="list-inline">
      <li
        v-for="i in socialMediaToDisplay"
        :key="i.key"
        class="list-inline-item"
      >
        <vendor-social-button
          :vendor="vendor"
          :href="i.url"
          :icon="i.key.toLowerCase()"
          :title="i.key"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import VendorHeaderActions from "./VendorHeaderActions";
import VendorSocialButton from "./VendorSocialButton";

const VendorFavoriteToggle = () =>
  import(
    /* webpackChunkName: "favoriteToggle" */ "../VendorFavoriteToggle.vue"
  );

export default {
  name: "VendorHeader",
  components: { VendorSocialButton, VendorHeaderActions, VendorFavoriteToggle },
  props: {
    vendor: { type: Object, required: true }
  },
  computed: {
    showTitle() {
      return this.vendor.showTitle || !this.vendor.logoSrc;
    },
    socialMediaToDisplay() {
      if (!this.vendor.social) return [];
      const s = [
        { key: "Facebook" },
        { key: "Twitter" },
        { key: "LinkedIn" },
        { key: "Instagram" },
        { key: "YouTube" }
      ];
      Object.entries(this.vendor.social).forEach(i => {
        let item = s.find(j => j.key.toLowerCase() === i[0].toLowerCase());
        if (item) item.url = i[1];
        else s.push({ key: i[0], url: i[1] });
      });
      return s.filter(i => i.url);
    }
  }
};
</script>

<style scoped lang="scss">
#vendor-header {
  position: relative;
}

.top-actions {
  position: absolute;
  right: 5px;
  top: 5px;

  @media (min-width: 576px) {
    right: 10px;
    top: 10px;
  }
}

img {
  max-width: 260px;
  max-height: 130px;
}

h1 {
  font-size: 2.6em;
  font-weight: 900;
}

p {
  font-size: 0.9em;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.btn.manage {
  font-size: 0.7em;
  font-weight: 600;
}
</style>
