<template>
  <div v-if="show" id="promos" class="platter mb-4 pb-3">
    <h3 class="mb-3">
      <strong>Promotions</strong>
    </h3>

    <b-row cols="1" cols-md="2">
      <b-col v-for="p in promos" :key="p.title" class="mb-3">
        <strong>{{ p.title }}</strong>
        <p class="mb-0">{{ p.copy }}</p>
        <p v-if="p.code" class="mb-0">
          <small>
            Code: <code>{{ p.code }}</code>
          </small>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "VendorPromos",
  props: {
    promos: { type: Array }
  },
  computed: {
    show() {
      return (this.promos?.length ?? 0) > 0;
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#promos",
        title: "Promotions",
        icon: "bullhorn"
      });
    }
  }
};
</script>
