<template>
  <div v-if="show" id="videos" class="platter mb-4 pb-3">
    <transition name="fade">
      <h3 v-if="showTitle" class="mb-3">
        <strong>Videos</strong>
      </h3>
    </transition>

    <div class="mb-3">
      <b-embed
        type="iframe"
        aspect="16by9"
        :src="featuredVideo.sourceSrc"
        allowfullscreen
      />
    </div>

    <transition name="fade">
      <div v-if="expanded">
        <div v-for="v in otherVideos" :key="v.id" class="mb-3">
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="v.sourceSrc"
            allowfullscreen
          />
        </div>
      </div>
    </transition>

    <toggle-more-button
      v-if="showToggleMore"
      :expanded="expanded"
      @click="$emit('toggle-expanded')"
    />
  </div>
</template>

<script>
import ToggleMoreButton from "./ToggleMoreButton";

import Enumerable from "linq";

export default {
  name: "VendorVideo",
  components: { ToggleMoreButton },
  props: {
    videos: { type: Array },
    expanded: { type: Boolean }
  },
  computed: {
    show() {
      return !!this.featuredVideo;
    },
    showTitle() {
      return true;
      //return this.expanded && this.showToggleMore;
    },
    showToggleMore() {
      return (this.otherVideos.length ?? 0) > 0;
    },
    videoEnumerable() {
      return Enumerable.from(this.videos);
    },
    featuredVideo() {
      return this.videoEnumerable.orderBy(v => !v.featured).firstOrDefault();
    },
    otherVideos() {
      return this.videoEnumerable
        .orderBy(v => !v.featured)
        .skip(1)
        .toArray();
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#videos",
        title: "Videos",
        icon: "video"
      });
    }
  }
};
</script>

<style scoped lang="scss">
#videos {
  padding: 15px;
}

.embed-responsive {
  //border-radius: 15px;
}
</style>