<template>
  <div class="vendor-contact d-flex align-items-center mb-4">
    <vc-headshot :src="contact.headshotSrc" class="mr-2" />
    <h6 class="mb-0">
      <strong>{{ fullName }}</strong>
      <br />
      <template v-if="contact.title">
        <small>{{ contact.title }}</small>
        <br />
      </template>
      <a v-if="contact.email" :href="emailLink" class="text-muted">
        <font-awesome-icon
          icon="envelope"
          class="mr-1"
          fixed-width
          title="Email"
          size="lg"
        />
      </a>
      <a v-if="contact.phone" :href="phoneLink" class="text-muted">
        <font-awesome-icon
          icon="phone"
          class="mr-1"
          fixed-width
          title="Phone"
          size="lg"
        />
      </a>
    </h6>
  </div>
</template>

<script>
export default {
  name: "VendorContact",
  props: {
    contact: { type: Object, required: true }
  },
  computed: {
    fullName() {
      return `${this.contact.firstName} ${this.contact.lastName}`;
    },
    emailLink() {
      return `mailto:${this.contact.email}`;
    },
    phoneLink() {
      return `tel:${this.contact.phone}`;
    }
  }
};
</script>
