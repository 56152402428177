<template>
  <div class="d-flex mb-1">
    <font-awesome-icon icon="quote-left" class="mr-2" size="lg" fixed-width />
    <blockquote class="blockquote">
      <p class="mb-0">
        {{ testimonial.copy }}
      </p>
      <footer class="blockquote-footer">
        {{ testimonial.author }}
      </footer>
    </blockquote>
  </div>
</template>

<script>
export default {
  name: "VendorTestimonial",
  props: {
    testimonial: { type: Object, required: true }
  }
};
</script>
