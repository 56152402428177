<template>
  <div id="vendor" class="vendor">
    <vc-app-header />

    <b-card
      v-if="!hasError && loaded && !vendor.active"
      class="bg-danger text-light mb-3"
      body-class="p-2 text-center"
    >
      <strong>This vendor is not currently active.</strong>
    </b-card>

    <div v-if="routeData || nextVendor || prevVendor" class="clearfix mb-3">
      <div class="float-left">
        <b-button
          v-if="routeData"
          :to="routeData.fullPath"
          variant="outline-dark"
          size="sm"
          pill
        >
          <font-awesome-icon icon="arrow-circle-left" fixed-width />
          Back to {{ routeData.name }}
        </b-button>
      </div>
      <div v-if="prevVendor || nextVendor" class="float-right">
        <b-button
          v-if="prevVendor"
          :to="{
            name: 'Vendor',
            params: {
              vendorKey: prevVendor.key,
              vendorList: this.vendorList,
              routeData: this.routeData
            }
          }"
          variant="outline-dark"
          size="sm"
          pill
          :title="'Previous Result: ' + prevVendor.name"
        >
          <font-awesome-icon icon="arrow-left" fixed-width />
        </b-button>
        <b-button v-else disabled size="sm" variant="outline-dark" pill>
          <font-awesome-icon icon="arrow-left" fixed-width />
        </b-button>

        <small class="mx-1 text-muted">
          {{ vendorPositionInList + 1 }} / {{ vendorList.length }}
        </small>

        <b-button
          v-if="nextVendor"
          :to="{
            name: 'Vendor',
            params: {
              vendorKey: nextVendor.key,
              vendorList: this.vendorList,
              routeData: this.routeData
            }
          }"
          variant="outline-dark"
          size="sm"
          pill
          :title="'Next Result: ' + nextVendor.name"
        >
          <font-awesome-icon icon="arrow-right" fixed-width />
        </b-button>
        <b-button v-else disabled size="sm" variant="outline-dark" pill>
          <font-awesome-icon icon="arrow-right" fixed-width />
        </b-button>
      </div>
    </div>

    <transition name="custom" enter-active-class="fadeIn">
      <div v-if="loaded && !hasError">
        <vendor-header
          :vendor="vendor"
          @navigate-to="handleNavigateTo($event)"
        />

        <vendor-featured-promo :promos="vendor.promos" />

        <b-row>
          <b-col id="widgets" sm="8" lg="9" order="2">
            <vendor-description
              :title="vendor.longDescTitle"
              :copy="vendor.longDesc"
              @add-nav="addNavItem($event)"
            />

            <vendor-video
              :videos="vendor.videos"
              :expanded="isExpanded('#videos')"
              @add-nav="addNavItem($event)"
              @toggle-expanded="toggleExpanded('#videos')"
            />

            <vendor-products
              :products="vendor.products"
              @add-nav="addNavItem($event)"
            />

            <vendor-services
              :services="vendor.services"
              @add-nav="addNavItem($event)"
            />

            <vendor-promos
              :promos="vendor.promos"
              @add-nav="addNavItem($event)"
            />

            <vendor-testimonials
              :testimonials="vendor.testimonials"
              :expanded="isExpanded('#testimonials')"
              @add-nav="addNavItem($event)"
              @toggle-expanded="toggleExpanded('#testimonials')"
            />

            <vendor-contacts
              :contacts="vendor.contacts"
              :expanded="isExpanded('#contacts')"
              @add-nav="addNavItem($event)"
              @toggle-expanded="toggleExpanded('#contacts')"
            />
          </b-col>
          <b-col sm="4" lg="3" order="1">
            <vc-plug-in-nav
              v-if="showNav"
              :nav-items="navItems"
              @navigate-to="handleNavigateTo($event)"
            />
          </b-col>
        </b-row>
      </div>
    </transition>

    <transition name="custom" enter-active-class="fadeIn">
      <vc-loading v-if="!loaded">Loading Vendor Data...</vc-loading>
    </transition>

    <div v-if="hasError" class="text-muted">
      <h4 class="text-center">{{ errorMessage }}</h4>
    </div>
  </div>
</template>

<script>
import VendorHeader from "@/components/Vendor/Profile/VendorHeader.vue";
import VendorFeaturedPromo from "@/components/Vendor/Profile/VendorFeaturedPromo.vue";
import VendorDescription from "@/components/Vendor/Profile/VendorDescription.vue";
import VendorVideo from "@/components/Vendor/Profile/VendorVideo.vue";
import VendorProducts from "@/components/Vendor/Profile/VendorProducts.vue";
import VendorPromos from "@/components/Vendor/Profile/VendorPromos.vue";
import VendorTestimonials from "@/components/Vendor/Profile/VendorTestimonials.vue";
import VendorContacts from "@/components/Vendor/Profile/VendorContacts.vue";
import VendorServices from "@/components/Vendor/Profile/VendorServices.vue";

export default {
  name: "VendorProfile",
  components: {
    VendorHeader,
    VendorFeaturedPromo,
    VendorDescription,
    VendorVideo,
    VendorProducts,
    VendorPromos,
    VendorTestimonials,
    VendorContacts,
    VendorServices
  },
  props: {
    vendorKey: { type: String, required: true },
    vendorList: { type: Array },
    routeData: { type: Object }
  },
  data() {
    return {
      navItems: [],
      vendor: {},
      loaded: false,
      expanded: [],
      hasError: false,
      errorMessage: ""
    };
  },
  computed: {
    showNav() {
      return this.navItems.length > 0;
    },
    hasVendorsList() {
      return !!this.vendorList;
    },
    vendorPositionInList() {
      return this.vendorList.findIndex(i => i.key == this.vendor.key);
    },
    nextVendor() {
      if (!this.hasVendorsList || this.vendorList.length <= 1) return undefined;
      return this.vendorList[this.vendorPositionInList + 1];
    },
    prevVendor() {
      if (!this.hasVendorsList || !this.vendorPositionInList) return undefined;
      return this.vendorList[this.vendorPositionInList - 1];
    }
  },
  watch: {
    vendorKey() {
      this.fetchData();
    },
    vendor() {
      document.title = `${this.vendor?.name} | ${this.$store.getters.appName}`;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      //If the existing vendor data matches with the requested key
      //there is no need to fetch data from the API
      if (this.vendor?.key === this.vendorKey) return;

      this.loaded = false;
      this.hasError = false;
      this.navItems = [];
      this.$store
        .dispatch("fetchVendorByKey", this.vendorKey)
        .then(
          v => {
            this.vendor = v;
            this.redirectToCanonicalKey();
            this.$store.dispatch("log", { action: "profile-view", vendorKey: v?.key });
          },
          e => {
            this.hasError = true;
            this.errorMessage = e.message;
            document.title = `${e.message} | ${this.$store.getters.appName}`;
          }
        )
        .finally(() => (this.loaded = true));
    },
    redirectToCanonicalKey() {
      //If accessing using an alias, redirect to canonical key
      if (this.vendor.key === this.vendorKey) return;
      this.$router.replace({
        name: "Vendor",
        params: {
          vendorKey: this.vendor?.key,
          vendorList: this.vendorList,
          routeData: this.routeData
        }
      });
    },
    toggleExpanded(w) {
      if (this.expanded.find(e => e === w)) {
        this.expanded = this.expanded.filter(e => e !== w);
      } else {
        this.expanded.push(w);
      }
    },
    handleNavigateTo(w) {
      this.$gtag.event(w.title, {
        event_category: "vendor",
        event_label: this.vendor.name
      });
      if (!this.isExpanded(w.href)) {
        this.toggleExpanded(w.href);
      }
    },
    isExpanded(w) {
      return !!this.expanded.find(e => e === w);
    },
    addNavItem(event) {
      if (this.navItems.find(i => i.href === event.href)) return;
      this.navItems.push(event);
    }
  }
};
</script>
