<template>
  <div v-if="show" id="products" class="platter mb-4">
    <h3 class="mb-3">
      <strong>Products</strong>
    </h3>

    <b-row cols="1" cols-md="2">
      <b-col v-for="p in products" :key="p.title">
        <b-img-lazy :src="p.imageSrc" fluid block class="mb-1" />
        <strong>{{ p.title }}</strong>
        <p>{{ p.copy }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "VendorProducts",
  props: {
    products: { type: Array }
  },
  computed: {
    show() {
      return (this.products?.length ?? 0) > 0;
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#products",
        title: "Products",
        icon: "box-open"
      });
    }
  }
};
</script>
