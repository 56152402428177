<template>
  <ul id="header-actions" class="list-inline mb-1">
    <!--<li v-if="hasChat" class="list-inline-item mb-2">
      <b-button variant="outline-dark">
        <font-awesome-icon icon="comment-dots" />
        Chat Now
      </b-button>
    </li>-->
    <li v-if="hasWebsite" class="list-inline-item mb-2">
      <b-button
        :href="vendor.website"
        target="_blank"
        rel="noopener"
        variant="outline-dark"
        @click="track('website')"
      >
        <font-awesome-icon icon="globe" />
        Website
      </b-button>
    </li>
    <li
      v-if="hasContacts"
      class="list-inline-item mb-2"
      v-scroll-to="'#contacts'"
      @click="toContacts"
    >
      <b-button variant="outline-dark">
        <font-awesome-icon icon="users" />
        Contacts
      </b-button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "VendorHeaderActions",
  props: {
    vendor: { type: Object }
  },
  computed: {
    hasContacts() {
      return (this.vendor?.contacts?.length ?? 0) > 0;
    },
    hasWebsite() {
      return !!this.vendor?.website;
    },
    hasChat() {
      return !!this.vendor?.canChat;
    }
  },
  methods: {
    toContacts() {
      this.$emit("navigate-to", "#contacts");
      this.track("contacts");
    },
    track(e) {
      this.$gtag.event(e, {
        event_category: "vendor",
        event_label: this.vendor.name
      });
    }
  }
};
</script>

<style scoped lang="scss">
#header-actions {
  // @media (max-width: 575px) {
  //   li {
  //     display: block;
  //     margin-right: 0;
  //   }

  //   .btn {
  //     width: 100%;
  //   }
  // }
}
</style>