<template>
  <div id="featured-promo" v-if="featuredPromo" class="platter mb-4">
    <h4 class="mb-1">{{ featuredPromo.title }}</h4>
    <p class="mb-0">{{ featuredPromo.copy }}</p>
  </div>
</template>

<script>
export default {
  name: "VendorFeaturedPromo",
  props: {
    promos: { required: true }
  },
  computed: {
    featuredPromo() {
      return this.promos?.find(p => p.isFeatured);
    }
  }
};
</script>

<style scoped lang="scss">
#featured-promo {
  padding: 15px;
  text-align: center;
}

h4 {
  font-weight: 900;
}

p {
  font-size: 1.05em;
  font-weight: 600;
}
</style>